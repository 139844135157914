import { CacheProvider } from '@emotion/react'
import * as React from 'react'
import Head from 'next/head'
import CssBaseline from '@mui/material/CssBaseline'
import PropTypes from 'prop-types'
import { DarkModeContextProvider } from '../src/DarkModeContext'
import SessionProvider from '../src/SessionProvider'
import ThemeProvider from '../src/ThemeProvider'
import '../src/airbrake'
import createEmotionCache from '../src/createEmotionCache'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

export default function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps },
}) {
  return (
    <SessionProvider session={session}>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <title>Edge Insights</title>
        </Head>
        <DarkModeContextProvider>
          <ThemeProvider>
            <CssBaseline />
            <Component {...pageProps} />
          </ThemeProvider>
        </DarkModeContextProvider>
      </CacheProvider>
    </SessionProvider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}
