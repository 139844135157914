import {
  SessionProvider as NextAuthSessionProvider,
  signIn as nextAuthSignIn,
} from 'next-auth/react'

export default NextAuthSessionProvider

let signingIn = false

/**
 * Redirects the user to the IDS login page.
 */
export function signIn() {
  if (!signingIn) {
    signingIn = true
    return nextAuthSignIn('ids')
  }
}
