import React, { useContext } from 'react'
import GlobalStyles from '@mui/material/GlobalStyles'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'
import DarkModeContext from './DarkModeContext'
import { dark, light } from './themes'

export default function ThemeProvider({ children }) {
  const { darkMode } = useContext(DarkModeContext)

  const globalStyles = (
    <GlobalStyles
      styles={{
        code: {
          fontSize: '13px',
          whiteSpace: 'pre',
          fontFamily: 'Menlo, monospace',
        },
        ...(darkMode ? dark.globalStyles : light.globalStyles),
      }}
    />
  )

  const theme = createTheme({
    typography: {
      fontFamily: '"Inter", sans-serif',
    },
    ...(darkMode ? dark.theme : light.theme),

    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            padding: '3px 12px',
          },
          sizeSmall: {
            lineHeight: '24px',
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.875rem',
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
          },
        },
      },

      MuiAutocomplete: {
        styleOverrides: {
          option: {
            fontSize: '0.875rem',
          },
        },
      },

      ...(darkMode ? dark.components : light.components),
    },
  })

  return (
    <MuiThemeProvider theme={theme}>
      {globalStyles}
      {children}
    </MuiThemeProvider>
  )
}
