import React, { createContext, useEffect, useState } from 'react'

const DarkModeContext = createContext()

function isOsDark() {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )
}

function getInitial() {
  try {
    const local = localStorage.getItem('darkMode')
    if (local !== undefined) return local === 'true'
  } catch (e) {
    // localstorage access can fail for various reasons, like browser settings or plugins, just ignore
  }

  return isOsDark()
}

export default DarkModeContext

export const DarkModeContextProvider = ({ children }) => {
  // Initialize dark mode by checking user setting, local storage setting, or OS setting
  const [darkMode, setDarkMode] = useState(getInitial())

  useEffect(() => {
    try {
      localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
    } catch (e) {
      // might throw due to permission issues
    }
  }, [darkMode])

  const value = {
    darkMode,
    setDarkMode,
  }

  return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>
}
