import { Notifier } from '@airbrake/browser'

const airbrake =
  process.env.NODE_ENV === 'production'
    ? new Notifier({
        projectId: 488483,
        projectKey: 'b7e442ed0619378cbfc8ea8f6e6f5fe2',
      })
    : null

export function logError(error, info) {
  if (process.env.NODE_ENV === 'production') {
    airbrake.notify({
      error: error,
      params: { info: info },
    })
  }
}
