import { grey } from '@mui/material/colors'
import chartColors from './chartColors'

const theme = {
  globalStyles: {
    input: {
      colorScheme: 'light',
    },
  },
  theme: {
    palette: {
      mode: 'light',
      chart: chartColors,
      bodyDivider: '#ECEDEE',
      background: {
        appBar: '#fff',
        header: grey[100],
        main: '#fbfafa',
        default: '#fff',
        paper: '#fff',
      },
    },
  },
}

export default theme
