import { lighten } from '@mui/material'
import chartColors from './chartColors'

const theme = {
  globalStyles: {
    body: {
      backgroundColor: '#060A0E',
    },
    a: {
      color: 'white',
    },
    input: {
      colorScheme: 'dark',
    },
  },
  theme: {
    palette: {
      mode: 'dark',
      chart: chartColors.map((c) => lighten(c, 0.35)),
      bodyDivider: '#16232E',
      background: {
        default: '#060A0E',
        paper: '#101820',
        appBar: '#060A0E',
        main: '#0A0E12',
        header: '#101820',
      },
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#0D1117',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          background: '#0D1117',
        },
      },
    },
  },
}

export default theme
